<template>
  <div id="app">
    <div class="countdown-container">
      <h1>纪念日倒计时（过去时间计算）</h1>
      <label for="date">输入纪念日日期：</label>
      <input type="date" id="date" v-model="targetDate" @change="updateCountdown" />
      <div v-if="elapsedTime">
        <h2>距离 {{ targetDate }} 已经过了:</h2>
        <p>
          {{ elapsedTime.days }} 天
          {{ elapsedTime.hours }} 小时
          {{ elapsedTime.minutes }} 分钟
          {{ elapsedTime.seconds }} 秒
          {{ elapsedTime.milliseconds }} 毫秒
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetDate: "", // 用户输入的纪念日日期
      elapsedTime: null, // 经过的时间
      timer: null, // 定时器ID
    };
  },
  methods: {
    // 更新过去时间计算逻辑
    updateCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // 清除之前的定时器
      }

      if (!this.targetDate) {
        this.elapsedTime = null;
        return;
      }

      const targetTimestamp = new Date(this.targetDate).getTime();

      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const diff = now - targetTimestamp; // 计算过去的时间差

        if (diff <= 0) {
          clearInterval(this.timer);
          this.elapsedTime = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          };
        } else {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          const milliseconds = diff % 1000;

          this.elapsedTime = { days, hours, minutes, seconds, milliseconds };
        }
      }, 1); // 每毫秒更新一次
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 组件销毁时清理定时器
    }
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}

.countdown-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid #4caf50;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h1 {
  color: #4caf50;
}

input[type="date"] {
  padding: 5px;
  font-size: 16px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

p {
  font-size: 20px;
  color: #333;
}
</style>
